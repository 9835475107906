import { useMeasure } from "react-use/esm/index.js";

import type { ReactNode, RefObject } from "react";
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { debounce } from "~zipdeal-ui/utilities/debounce.ts";

export interface ContentSizeInterface {
	contentHeight;
	footerHeight;
	headerHeight;
	titleHeight;
	appHeight;
	contentWidth;
	contentHeightOffset;
	headerRef;
	footerRef;
	titleRef;
	contentOffsetTargetRef;
	headerReactRef;
	footerReactRef;
	portalsRef;
}

const ContentSizeContext = createContext<ContentSizeInterface | undefined>(
	undefined,
);

const debouncedSetPropertyOnDocumentElementFactory = () =>
	debounce(([property, value]) => {
		const doc = document.documentElement;
		doc.style.setProperty(property, value);
	}, 100);

const setHeaderPropertyOnDocumentElement =
	debouncedSetPropertyOnDocumentElementFactory();
const setTitlePropertyOnDocumentElement =
	debouncedSetPropertyOnDocumentElementFactory();
const setFooterPropertyOnDocumentElement =
	debouncedSetPropertyOnDocumentElementFactory();
const setContentHeightPropertyOnDocumentElement =
	debouncedSetPropertyOnDocumentElementFactory();
const setContentHeightOffsetPropertyOnDocumentElement =
	debouncedSetPropertyOnDocumentElementFactory();

export const ContentSizeProvider = ({
	portalsRef,
	children,
}: {
	portalsRef: RefObject<HTMLDivElement>;
	children?: ReactNode;
}) => {
	const [headerRef, { width: headerWidth, height: headerHeight }] =
		useMeasure();
	const [footerRef, { width: footerWidth, height: footerHeight }] =
		useMeasure();

	const footerReactRef = useRef(null);
	const headerReactRef = useRef(null);

	const [appHeight, setAppHeight] = useState(0);
	const [titleRef, { width: titleWidth, height: titleHeight }] = useMeasure();

	const contentOffsetTargetRef = React.useRef(null);

	const contentWidth = footerWidth;

	useEffect(() => {
		const handleSize = () => {
			const doc = document.documentElement;
			// showToast({ text: `--app-height ${window.innerHeight}px`, duration: 20000 });
			setAppHeight(window.innerHeight);
			doc.style.setProperty("--app-height", `${window.innerHeight}px`);
		};

		const debouncedHandler = debounce(handleSize, 100);
		window.addEventListener("resize", debouncedHandler);
		handleSize();
		return () => {
			window.removeEventListener("resize", debouncedHandler);
		};
	}, []);

	const contentHeight = appHeight - (headerHeight || 0) - (footerHeight || 0);

	const contentHeightOffset = useMemo(() => {
		if (contentOffsetTargetRef.current) {
			const computedStyle = window.getComputedStyle(
				contentOffsetTargetRef.current,
			);
			const paddingTop = parseInt(
				computedStyle.getPropertyValue("padding-top").replace("px", ""),
				10,
			);
			const paddingBottom = parseInt(
				computedStyle.getPropertyValue("padding-bottom").replace("px", ""),
				10,
			);
			const paddingLeft = parseInt(
				computedStyle.getPropertyValue("padding-left").replace("px", ""),
				10,
			);
			const paddingRight = parseInt(
				computedStyle.getPropertyValue("padding-right").replace("px", ""),
				10,
			);

			return paddingTop + paddingBottom + (titleHeight || 0) || 0;
		}
		return 0;
		// return () => {
		//   setContentHeightOffset(0);
		// };
	}, [titleHeight]);

	useEffect(() => {
		setHeaderPropertyOnDocumentElement([
			"--header-height",
			`${headerHeight}px`,
		]);
	}, [headerHeight]);
	useEffect(() => {
		setTitlePropertyOnDocumentElement(["--title-height", `${titleHeight}px`]);
	}, [titleHeight]);
	useEffect(() => {
		setFooterPropertyOnDocumentElement([
			"--footer-height",
			`${footerHeight}px`,
		]);
	}, [footerHeight]);
	useEffect(() => {
		setContentHeightOffsetPropertyOnDocumentElement([
			"--content-height-offset",
			`${contentHeightOffset}px`,
		]);
	}, [contentHeightOffset]);

	useEffect(() => {
		setContentHeightPropertyOnDocumentElement([
			"--content-height",
			`${contentHeight - contentHeightOffset}px`,
		]);
	}, [contentHeight, contentHeightOffset]);

	const context = useMemo(() => {
		return {
			contentHeight,
			footerHeight,
			headerHeight,
			titleHeight,
			appHeight,
			contentHeightOffset,
			headerRef,
			footerRef,
			titleRef,
			contentOffsetTargetRef,
			contentWidth,
			headerReactRef,
			footerReactRef,
			portalsRef,
		};
	}, [
		contentHeight,
		footerHeight,
		headerHeight,
		titleHeight,
		appHeight,
		contentHeightOffset,
		headerRef,
		footerRef,
		titleRef,
		contentWidth,
		headerReactRef,
		footerReactRef,
		portalsRef,
	]);
	return (
		<ContentSizeContext.Provider value={context}>
			{children}
		</ContentSizeContext.Provider>
	);
};

export const useContentSize = () => {
	const context = useContext(ContentSizeContext);
	if (context === undefined) {
		throw Error("You need to useContentSize in a ContentSizeProvider!");
	}
	return context;
};

export const usePortalsRefElement = () => {
	const context = useContext(ContentSizeContext);
	return context?.portalsRef?.current;
};
